// Context/GetInTouchContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const GetInTouchContext = createContext();

// Create the provider component
export const GetInTouchProvider = ({ children }) => {
    const [getInTouchVisible, setGetInTouchVisible] = useState(false);

    // Function to toggle the visibility
    const toggleGetInTouchVisible = (value) => {
        setGetInTouchVisible(value);
    };

    return (
        <GetInTouchContext.Provider value={{ getInTouchVisible, toggleGetInTouchVisible }}>
            {children}
        </GetInTouchContext.Provider>
    );
};