import React, { useContext } from 'react';
import './watermark.css';
import '../../Constants/Global.css'
import { ThemeContext } from './../../Context/ThemeContext';

function Watermark() {
    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);

    // Const
    const style = {
        'textColor': { color: `${theme.secondaryColor}05` }
    }

    return (
        <div className="watermark-div">
            <div className='watermark-text watermark-firstname-location no-select' style={style.textColor}>Piyush</div>
            <div className='watermark-text watermark-lastname-location no-select' style={style.textColor}>Saklani</div>
        </div>
    );
}

export default Watermark;
