import React, { useContext, useEffect, useState, useRef } from 'react';
import './App.css';
import './Constants/Global.css'
import Appbar from './Utils/Appbar/Appbar';
import { ThemeContext } from './Context/ThemeContext';
import LandingSection from './Components/LandingSection/LandingSection';
import WorkBanner from './Utils/WorkBanner/WorkBanner';
import FirstBanner from './Components/FirstBanner/FirstBanner';
import WorkSection from './Components/WorkSection/WorkSection';

function App() {

  // useContexts
  const { theme, toggleTheme } = useContext(ThemeContext);

  // useState
  const [isOpen, setIsOpen] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [projectName, setProjectName] = useState("");

  // Const
  const style = {
    'app': { backgroundColor: isOpen ? theme.secondaryColor : theme.primaryColor },
    'details': { color: theme.secondaryColor }
  }

  // Function
  const handleCompleted = () => setIsOpen(!isOpen);

  const handlePlayingDemo = () => setIsPlaying(true);

  const handleCloseDemo = () => setIsPlaying(false);

  return (
    <div className="main-section" style={style.app}>
      <div className='appbar-div' style={{ position: !isOpen && 'fixed' }}><Appbar complete={handleCompleted} isOpen={isOpen} isPlaying={isPlaying} closeDemo={handleCloseDemo} projectName={projectName} /></div>
      {!isOpen &&
        <div className='sections'>
          <LandingSection />
          <FirstBanner />
          <WorkSection playingDemo={(projectName) => {
            handlePlayingDemo();
            setProjectName(projectName);
          }} />
        </div>
      }
    </div>
  );
}

export default App;
