
import React, { useContext, useEffect, useState } from 'react';
import './Menuitem.css';
import '../../Constants/Global.css'
import { ThemeContext } from '../../Context/ThemeContext';
import { motion } from "framer-motion";
function Menuitem({ value }) {

    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);
    
    return (
        <motion.div
            className="box fade-in no-select"
            whileHover={{ scale: 1.1, color: theme.secondaryColor, backgroundColor: theme.primaryColor }}
            whileTap={{ scale: 0.9 }}
            whileInView={{ color: theme.primaryColor }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >{value}</motion.div>
    );
}

export default Menuitem;