import React, { useContext, useEffect, useState, useRef } from 'react';
import './Disc.css';
import '../../Constants/Global.css'
import { ThemeContext } from '../../Context/ThemeContext';
import { motion } from "framer-motion";
import { GetInTouchContext } from '../../Context/GetInTouchContext';

function Disc() {

    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);
    const { getInTouchVisible, toggleGetInTouchVisible } = useContext(GetInTouchContext);

    const elementRef = useRef(null);

    // Const
    const style = {
        'details': { color: theme.secondaryColor }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                );
                toggleGetInTouchVisible(!isVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        // Initial check on component mount
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const sendEmail = () => {
        window.location.href = "mailto:piyushsaklani@outlook.com";
        // window.location.href = "mailto:piyushsaklani@outlook.com?subject=Your%20Subject&body=This%20is%20the%20pre-filled%20body%20of%20the%20email.";
    }

    return (
        <div className='no-select' style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}}>
                <div className='my-name' style={{ color: theme.secondaryColor }}>Hi, I'm <strong>Piyush Saklani</strong></div>
                <div className='my-role' style={{ color: theme.secondaryColor }}>Software Engineer</div>
                <div className='my-dis' style={{ color: theme.secondaryColor }}>Skilled in full stack development, capable of creating <br />efficient and dynamic applications from <br />backend to frontend.</div>

                <div className='contact-div' ref={elementRef} style={{ color: theme.secondaryColor }} onClick={() => { }}>
                    <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} className='contact-button' style={{ backgroundColor: theme.secondaryColor }} onClick={() => { sendEmail() }}>
                        <div className='world-icon'></div>
                    </motion.div>
                    Get in touch
                </div>
        </div>
    );
}

export default Disc;
