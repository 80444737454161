import React, { useContext, useRef, useEffect, useState } from 'react';
import { ThemeContext } from '../../Context/ThemeContext';
import './WorkSection.css';
import { motion } from "framer-motion";
import PlayButton from '../../Utils/PlayButton/PlayButton';

function WorkSection({ playingDemo }) {

    const { theme, toggleTheme } = useContext(ThemeContext);

    // Project One
    const projectOneSvgRef = useRef(null);
    const projectOneRef = useRef(null);
    const [projectOneRightPosition, setProjectOneRightPosition] = useState(0);
    const [projectOneTopPosition, setProjectOneTopPosition] = useState(0);
    const [projectOnePlayButtonTop, setProjectOnePlayButtonTop] = useState(0);
    // Project Two
    const projectTwoSvgRef = useRef(null);
    const projectTwoRef = useRef(null);
    const [projectTwoLeftPosition, setProjectTwoLeftPosition] = useState(0);
    const [projectTwoTopPosition, setProjectTwoTopPosition] = useState(0);
    const [projectTwoPlayButtonTop, setProjectTwoPlayButtonTop] = useState(0);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    // Project Three
    const projectThreeSvgRef = useRef(null);
    const projectThreeRef = useRef(null);
    const [projectThreeRightPosition, setProjectThreeRightPosition] = useState(0);
    const [projectThreeTopPosition, setProjectThreeTopPosition] = useState(0);
    const [projectThreePlayButtonTop, setProjectThreePlayButtonTop] = useState(0);

    useEffect(() => {
        function calculatePosition() {
            if (projectOneSvgRef.current) {
                const svgRect = projectOneSvgRef.current.getBoundingClientRect();
                setProjectOneRightPosition(svgRect.right);
            }
            if (projectOneRef.current) {
                const svgRect = projectOneRef.current.getBoundingClientRect();
                setProjectOneTopPosition(svgRect.top);
            }
            setViewportWidth(window.innerWidth);
        }

        calculatePosition();

        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    useEffect(() => {
        function calculatePosition() {
            if (projectTwoSvgRef.current) {
                const svgRect = projectTwoSvgRef.current.getBoundingClientRect();
                setProjectTwoLeftPosition(svgRect.left);
            }
            if (projectTwoRef.current) {
                const svgRect = projectTwoRef.current.getBoundingClientRect();
                setProjectTwoTopPosition(svgRect.top);
            }
        }

        calculatePosition();

        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    useEffect(() => {
        function calculatePosition() {
            if (projectThreeSvgRef.current) {
                const svgRect = projectThreeSvgRef.current.getBoundingClientRect();
                setProjectThreeRightPosition(svgRect.right);
            }
            if (projectThreeRef.current) {
                const svgRect = projectThreeRef.current.getBoundingClientRect();
                setProjectThreeTopPosition(svgRect.top);
            }
        }

        calculatePosition();

        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    return (
        <div className='work-section'>
            {/* Project One */}
            <div className='p1-div' ref={projectOneRef}>
                <div className='p1-svg' ref={projectOneSvgRef}>
                    <svg style={{ height: '100vh' }} viewBox="0 0 605 1001" fill="none">
                        <path d="M199 9C240.333 95 358.2 270.5 499 284.5C675 302 521.5 758.5 271 803C70.6 838.6 20.5 949.833 20.5 1001" stroke="#F7F7FF" stroke-width="50" />
                    </svg>
                    <PlayButton
                        playingDemo={() => { playingDemo("SpendCal"); }}
                        customClassName={"spendCal-play-button"}
                        playButtonTop={(projectOnePlayButtonTop) => { setProjectOnePlayButtonTop(projectOnePlayButtonTop) }}
                    />
                </div>
                <div className='p1-text'
                    style={{
                        marginLeft: `${((projectOneRightPosition / viewportWidth) * 100) + 1}%`,
                        marginTop: `${(projectOnePlayButtonTop - projectOneTopPosition) - 10}px`,
                        color: theme.secondaryColor
                    }}>
                    <div className='p1-title'>SpendCal</div>
                    <div className='p1-description'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, quam vero illum, quidem, ducimus dolores saepe aut veritatis ad nostrum adipisci repellendus nobis ab fugit possimus cum odit natus assumenda?</div>
                </div>
                <div className='p1-image'></div>
            </div>
            {/* Project Two */}
            <div className='p2-div' ref={projectTwoRef}>
                <div className='p2-svg' ref={projectTwoSvgRef}>
                    <svg style={{ height: '100vh' }} viewBox="0 0 503 874" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M446.5 13.5C365.667 106.167 147.18 254.004 39.9998 338.5C-54.5 413 203 946.5 496 839.5" stroke="#F7F7FF" stroke-width="40" />
                    </svg>

                    <PlayButton
                        playingDemo={() => { playingDemo("TravelCompanion"); }}
                        customClassName={"travelCompanion-play-button"}
                        playButtonTop={(projectTwoPlayButtonTop) => { setProjectTwoPlayButtonTop(projectTwoPlayButtonTop) }}
                    />
                </div>
                <div className='p2-image'></div>
                <div className='p2-text'
                    style={{
                        marginRight: `${100 - ((projectTwoLeftPosition / viewportWidth) * 100) + 1}%`,
                        marginTop: `${(projectTwoPlayButtonTop - projectTwoTopPosition) - 16}px`,
                        color: theme.secondaryColor
                    }}
                >
                    <div className='p2-title'>Travel Companion</div>
                    <div className='p2-description'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, quam vero illum, quidem, ducimus dolores saepe aut veritatis ad nostrum adipisci repellendus nobis ab fugit possimus cum odit natus assumenda?</div>
                </div>
            </div>
            <div className='p2-div'></div>
            {/* Project Three */}
            <div className='p3-div' ref={projectThreeRef}>
                <div className='p3-svg' ref={projectThreeSvgRef}>
                    <svg style={{ height: '100vh' }} viewBox="0 0 544 1253" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M77.5 5C106.167 143.833 331.8 365.3 487 470.5C642.2 575.7 274 1161.5 6 1233" stroke="#F7F7FF" stroke-width="40" />
                    </svg>
                    <PlayButton
                        playingDemo={() => { playingDemo("JobPower"); }}
                        customClassName={"jobPower-play-button"}
                        playButtonTop={(projectThreePlayButtonTop) => { setProjectThreePlayButtonTop(projectThreePlayButtonTop) }}
                    />
                </div>
                <div className='p3-text'
                    style={{
                        marginLeft: `${((projectThreeRightPosition / viewportWidth) * 100) + 1}%`,
                        marginTop: `${(projectThreePlayButtonTop - projectThreeTopPosition) - 10}px`,
                        color: theme.secondaryColor
                    }}>
                    <div className='p3-title'>SpendCal</div>
                    <div className='p3-description'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, quam vero illum, quidem, ducimus dolores saepe aut veritatis ad nostrum adipisci repellendus nobis ab fugit possimus cum odit natus assumenda?</div>
                </div>
                <div className='p3-image'></div>
            </div>
        </div>
    );
}

export default WorkSection;