import React from 'react';

function SkillBanner({ color, stock, text, textColor }) {
  const repeatedText = `${text} - ${text} - ${text}`;

  return (
    <svg 
      viewBox="0 0 1633 781" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      style={{ width: '100vw', height: 'auto' }} // Makes SVG responsive
      preserveAspectRatio="xMidYMid meet" // Maintains aspect ratio
    >
      <path
        id="curve"
        d="M-40 10.00002C77.6667 81.6666 244.4 3.69998 298 185.5C365 422 484 493 680.5 470C877 447 1376.5 112.5 1765.5 438.5"
        stroke={color}
        strokeWidth={stock}
      />
      <text fill={textColor} fontSize="18">
        <textPath href="#curve" alignmentBaseline="middle" style={{ fontFamily: 'Dosis', fontWeight: '500' }}>
          {repeatedText}
        </textPath>
      </text>
    </svg>
  );
}

export default SkillBanner;