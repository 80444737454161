// Themes.js
export const lightTheme = {
    title: 'lightTheme',
    primaryColor: '#f7f7ff',
    secondaryColor: '#343a40',
    focusColor: '#ff3366',
};

export const darkTheme = {
    title: 'darkTheme',
    primaryColor: '#343a40',
    secondaryColor: '#f7f7ff',
    focusColor: '#ff3366',
};
