import React, { useContext, useState } from 'react';
import Disc from '../../Utils/Disc/Disc';
import Watermark from '../../Utils/Watermark/watermark';
import Appbar from '../../Utils/Appbar/Appbar';
import { ThemeContext } from '../../Context/ThemeContext';
import './LandingSection.css';
import '../../Constants/Global.css';

function LandingSection() {

    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);

    // useState
    const [isOpen, setIsOpen] = useState(true);

    // Const
    const style = {
        'app': { backgroundColor: isOpen ? theme.secondaryColor : theme.primaryColor },
        'details': { color: theme.secondaryColor }
    }

    // Function
    const handleCompleted = () => setIsOpen(!isOpen);

    return (
        <div className='main landing-page'>
            <Disc />
            <Watermark />
            {/* <Appbar complete={handleCompleted} isOpen={isOpen} /> */}
        </div >
    )
}

export default LandingSection;