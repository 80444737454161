import React, { useContext, useEffect, useState, useSyncExternalStore } from 'react';
import './Greeting.css';
import '../../Constants/Global.css'
import { ThemeContext } from '../../Context/ThemeContext';
import { variables } from '../../Constants/Constants';

function Greeting({ completed }) {

    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);
    
    // Const
    const greetings = variables.greetings;
    const style = {
        greeting: { color: theme.primaryColor }
    }
    
    // useState
    const [index, setIndex] = useState(0);
    
    // useEffect
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => prevIndex + 1);
            index === greetings.length && completed();
        }, index === 0 || index === greetings.length - 1 ? 1000 : 200);
        return () => clearInterval(interval);
    }, [index, greetings.length]);
    
    return (
        <div className='fade-in greeting no-select' style={style.greeting}>{greetings[index]}</div>
    );
}

export default Greeting;
