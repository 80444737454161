import React, { useContext } from 'react';
import SkillBanner from '../../Assets/SVG/SkillBanner';
import { ThemeContext } from '../../Context/ThemeContext';
import './FirstBanner.css';
import { motion } from "framer-motion";

function FirstBanner() {

    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <div className='test-div'>
            <SkillBanner
                color={theme.secondaryColor}
                stock={50}
                textColor={theme.primaryColor}
                text={"Software Engineer with expertise in full stack development, app and web development, and a strong focus on UI/UX design."}
            />
        </div>
    );
}

export default FirstBanner;