// ThemeContext.js
import { createContext, useState } from 'react';
import { lightTheme, darkTheme } from '../Constants/Themes';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const local_theme = JSON.parse(localStorage.getItem('theme'));
    return local_theme || darkTheme;
  });

  const toggleTheme = () => {
    const newtheme = theme === lightTheme ? darkTheme : lightTheme;
    localStorage.setItem('theme', JSON.stringify(newtheme));
    setTheme(newtheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
