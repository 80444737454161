import React, { useContext, useEffect, useState } from 'react';
import './Appbar.css';
import '../../Constants/Global.css'
import { ThemeContext } from '../../Context/ThemeContext';
import { motion } from "framer-motion";
import Greeting from '../Greeting/Greeting';
import Menuitem from '../Menuitem/Menuitem';
import { GetInTouchContext } from '../../Context/GetInTouchContext';
import Projects from '../../Constants/Projects.json';

function Appbar({ complete, isOpen, isPlaying, closeDemo, projectName }) {

    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);
    const { getInTouchVisible, toggleGetInTouchVisible } = useContext(GetInTouchContext);

    // useState
    const [isVisible, setIsVisible] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [inTouchVisible, setInTouchVisible] = useState(false);

    // Const
    const style = {
        'initial': { backgroundColor: theme.secondaryColor, borderRadius: 50 },
    }

    // Function
    const handleCompleted = () => complete();

    // useEffect
    useEffect(() => {
        if (!isOpen && !isPlaying) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 850); // .85s
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isPlaying) {
            setIsVisible(false);
        } else if (!isPlaying && !isVisible && !isOpen) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 850); // .85s
            return () => clearTimeout(timer);
        }
    }, [isPlaying]);

    useEffect(() => {
        if (getInTouchVisible) {
            setShowContact(true);
            setInTouchVisible(true);
        } else {
            setInTouchVisible(false);
            setShowContact(false);
        }
    }, [getInTouchVisible]);

    // Functions
    const handleCloseDemo = () => {
        closeDemo();
    }

    return (
        <motion.div
            layout
            data-isOpen={isOpen}
            data-inTouchVisible={inTouchVisible}
            data-isPlaying={isPlaying}
            initial={style.initial}
            className="parent"
            transition={{ type: "spring", stiffness: 125, damping: 12 }}
        >
            {(isOpen && !isPlaying) && <Greeting completed={handleCompleted} />}
            {(isVisible && !isPlaying) && (
                <>
                    <Menuitem value='Home' />
                    <Menuitem value='Work' />
                    <Menuitem value='Experties' />
                    {/* <Menuitem value='Experience' /> */}
                    {showContact && (
                        <motion.div
                            layout
                            className='contact-container'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ type: 'spring', stiffness: 125, damping: 12, delay: 0.05 }}
                        >
                            <Menuitem value="Get in Touch" />
                        </motion.div>
                    )}
                </>
            )}
            {isPlaying && <div className='demo-div'>
                <div className='demo-title-div no-select'>
                    <div className='demo-title'>{projectName}</div>
                    <div className='demo-close' onClick={() => { handleCloseDemo(); }}>Close Demo</div>
                </div>
                <video className="demo"
                    controls
                    muted
                    autoPlay
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                    onDoubleClick={(e) => e.preventDefault()}
                >
                    <source src={Projects.projectsToDisplay[projectName].video} type="video/mp4" />
                </video>
            </div>}
        </motion.div >
    );
}

export default Appbar;
