import React, { useContext, useRef, useEffect, useState } from 'react';
import { ThemeContext } from '../../Context/ThemeContext';
import './PlayButton.css';
import { motion } from "framer-motion";

function PlayButton({ playingDemo, customClassName, playButtonTop }) {

    // useContexts
    const { theme, toggleTheme } = useContext(ThemeContext);

    const [isVisible, setIsVisible] = useState(false);

    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 1,
            }
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);

    useEffect(() => {
        function calculatePosition() {
            if (elementRef.current) {
                const svgRect = elementRef.current.getBoundingClientRect();
                playButtonTop(svgRect.top);
            }
        }

        calculatePosition();

        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    return (
        <motion.div
            ref={elementRef}
            className={customClassName}
            style={{ backgroundColor: theme.secondaryColor }}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={isVisible ? { opacity: 1, scale: 1 } : {}}
            transition={{
                duration: 1,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001
                }
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
                playingDemo();
            }}
        >
            <svg width="17" height="20" viewBox="0 0 14 17" fill="none">
                <path d="M0.249882 2.87133C0.249882 1.33173 1.91655 0.369477 3.24988 1.13928L12.9999 6.76844C14.3332 7.53824 14.3332 9.46274 12.9999 10.2325L3.24988 15.8617C1.91655 16.6315 0.249883 15.6693 0.249883 14.1297L0.249882 2.87133Z" fill="#343A40" />
            </svg>

        </motion.div>
    );
}

export default PlayButton;